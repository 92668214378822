import axios from 'axios'
import router from '@/router'
import Swal from 'sweetalert2'

export default ({
	namespaced: true,
  state: {
  	authenticated: false,
  	user: null,
    icdx: null,
    cabang: null,
    hak_akses: null,
    level_jabatan: null
  },
  getters: {
  	authenticated(state){
  		return state.authenticated;
  	},
  	user(state){
  		return state.user;
  	},
    icdx(state){
      return state.icdx;
    },
    cabang(state){
      return state.cabang;
    },
    hak_akses(state){
      return state.hak_akses
    },
    level_jabatan(state){
      return state.level_jabatan
    }
  },
  mutations: {
    SET_AUTHENTICATED(state, value){ state.authenticated = value },
    SET_USER(state, value) { state.user = value },
    SET_ICDX(state, value) { state.icdx = value },
    SET_CABANG(state, value) { state.cabang = value },
    SET_HAK_AKSES(state, value) { state.hak_akses = value },
    SET_LEVEL_JABATAN(state, value) { state.level_jabatan = value },
  },
  actions: {
    async login ({dispatch}, credentials){
      await axios.get('sanctum/csrf-cookie')
      
      try {
        await axios.post('login', credentials)  
        dispatch('me')
      } catch {
        return 'wrong_credential'
      }
    },

    async logout({ commit }){
      await axios.post('logout')
      .then((response) => {
        Swal.fire({
          text: 'Berhasil logout',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        })
        commit("SET_AUTHENTICATED", false)
        commit("SET_USER", null)
        router.push('/login')  
        console.log(response)
      })
      .catch((error) => {
        Swal.fire({
          text: 'Gagal Logout',
          icon: 'error',
          confirmButtonText: 'Ok'
        })  
        console.log(error)
      })
    },

    async me({commit}){
      try{
        let response  = await axios.get('api/me')
        let cabang    = await axios.get(`api/cabang/getWhereId/${response.data.cabang_id}`)
        let hak_akses = await axios.get(`api/hak_akses/getMenu/${response.data.id}`)
        let karyawan  = await axios.get(`api/karyawan/getWhereId/${response.data.karyawan_id}`)

        commit("SET_AUTHENTICATED", true)
        commit("SET_USER", response.data)
        commit("SET_CABANG", cabang.data)
        commit("SET_HAK_AKSES", hak_akses.data)
        commit("SET_LEVEL_JABATAN", karyawan.data.level_jabatan ? karyawan.data.level_jabatan : 'pimpinan' )
        router.replace('/')
        router.push('/dashboard')
      } catch {
        commit("SET_AUTHENTICATED", false)
        commit("SET_USER", null)
        commit("SET_CABANG", null)
        commit("SET_HAK_AKSES", null)
        commit("SET_LEVEL_JABATAN", null)
      }
    },

    async getIcdx({commit}){
      try{
        let response = await axios.get('api/icdx/get')
        commit("SET_ICDX", response.data)
      } catch {
        commit("SET_USER", null)
      }


    }

  },
 
})
