<template>
  <div>
    <ContentHeader title="Dashboard" />
       <div class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 col-6" v-if="hak_akses.includes('kunjungan_pasien') || hak_akses.includes('semua')">
              <!-- small box -->
              <div class="small-box bg-info">
                <div class="inner">
                  <h3>{{ result.kunjungan }}</h3>

                  <p>Kunjungan Pasien</p>
                </div>
                <div class="icon">
                  <i class="ion ion-person"></i>
                </div>
              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6" v-if="hak_akses.includes('kunjungan_pasien_baru') || hak_akses.includes('semua')">
              <!-- small box -->
              <div class="small-box bg-success">
                <div class="inner">
                  <h3>{{ result.kunjungan_baru }}</h3>

                  <p>Kunjungan Pasien Baru</p>
                </div>
                <div class="icon">
                  <i class="ion ion-person-add"></i>
                </div>
              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6" v-if="hak_akses.includes('pendapatan_harian') || hak_akses.includes('semua')">
              <!-- small box -->
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3 class="text-white">{{ format_nominal(result.pendapatan) }}</h3>

                  <p class="text-white">Pendapatan</p>
                </div>
                <div class="icon">
                  <i class="ion ion-cash"></i>
                </div>
              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6" v-if="hak_akses.includes('pengeluaran_harian') || hak_akses.includes('semua')">
              <!-- small box -->
              <div class="small-box bg-danger">
                <div class="inner">
                  <h3>{{ format_nominal(result.pengeluaran) }}</h3>

                  <p>Pengeluaran</p>
                </div>
                <div class="icon">
                  <i class="ion ion-stats-bars"></i>
                </div>
              </div>
            </div>
            <!-- ./col -->
          </div>
        </div>
    </div>
    
    <div class="content-header" v-if="cabang.jenis == 'pusat' || hak_akses.includes('pendapatan_poli') || hak_akses.includes('semua')">
      <div class="container-fluid">
          <h4>Pendapatan Poli</h4>
          <div class="row">
            <div class="col-lg-3 col-6" v-for="(row, index) in result.poli" :key="index">
              <!-- small box -->
              <div class="small-box bg-secondary">
                <div class="inner">
                  <h3 class="text-white">{{ format_nominal(row.pendapatan) }}</h3>

                  <p class="text-white">Pendapatan Poli {{ row.nama }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="content-header" v-if="cabang.jenis == 'cabang' && hak_akses.includes('grafik_kunjungan') || hak_akses.includes('semua')">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Kunjungan Pasien</h3>
          </div>
          <div class="card-body">
            <v-chart class="chart" :option="kunjungan_option" v-if="cek_data_kunjungan == ''" />
            <p class="text-center mt-2">{{ cek_data_kunjungan }}</p>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>
    </div>

    <div class="content-header" v-if="cabang.jenis == 'cabang' && hak_akses.includes('grafik_pendapatan') || hak_akses.includes('semua')">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Pendapatan Klinik</h3>
          </div>
          <div class="card-body">
            <v-chart class="chart" :option="pendapatan_option" v-if="cek_data_pendapatan == ''" />
            <p class="text-center mt-2">{{ cek_data_pendapatan }}</p>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>
    </div>

  </div>  
</template>


<script>
import ContentHeader from '@/components/ContentHeader'

import { computed, reactive, onMounted, ref } from 'vue'
import store from '@/store'
import axios from 'axios'
import { useRouter } from 'vue-router' 

import format_nominal from '@/format_nominal'

import { use } from "echarts/core";
// import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import VChart from "vue-echarts";

use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

export default{
  components: {
    ContentHeader,
    VChart
  },
  setup() {
    const user = computed(() => store.getters['auth/user'])
    const cabang = computed(() => store.getters['auth/cabang'])
    const hak_akses = computed(() => store.getters['auth/hak_akses'])
    const cek_data_kunjungan = ref('')
    const cek_data_pendapatan = ref('')

    // fitur_membership
    const router = useRouter()
    if(cabang.value.kode_cabang == 'IDI') {
      router.push('/data-membership')
    }
    // fitur_membership

    const result = reactive({
      'kunjungan': '-',
      'kunjungan_baru': '-',
      'pendapatan': '-',
      'pengeluaran': '-'
    })

    const date = new Date()
    let awal = new Date(date.getFullYear(), date.getMonth(), 2)
    let akhir = new Date(date.getFullYear(), date.getMonth() + 1, 1)
    let periode_awal = awal.toISOString().slice(0, 10)
    let periode_akhir = akhir.toISOString().slice(0, 10)

    const getData = async () => {
      let { data } = await axios.get(`api/dashboard/${user.value.cabang_id}`)
      
      result.kunjungan      = data.kunjungan
      result.kunjungan_baru = data.kunjungan_baru
      result.pendapatan     = data.pendapatan
      result.pengeluaran    = data.pengeluaran
      result.poli           = data.poli
    }


    // Grafik Pendapatan 

    const kunjungan_option = ref({
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: []
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
      },
      yAxis: {
        type: 'value'
      },
      series: []
    })

    const pendapatan_option = ref({
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: []
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
      },
      yAxis: {
        type: 'value'
      },
      series: []
    })

    const getKunjungan = async () => {
      cek_data_kunjungan.value = 'Memuat Data...'
      let { data } = await axios.get(`api/analisis_statistik/kunjungan_pasien/${cabang.value.id}/${periode_awal}/${periode_akhir}/tanggal/line`)

        if (data != 'kosong') {
          cek_data_kunjungan.value = ''
          kunjungan_option.value.legend.data = data.legend
          kunjungan_option.value.xAxis.data = data.xAxis
          kunjungan_option.value.series = data.series
        } else {
          cek_data_kunjungan.value = 'Tidak Ada Data Kunjungan'
        } 
    }

    const getPendapatan = async () => {
      cek_data_pendapatan.value = 'Memuat Data...'
      let { data } = await axios.get(`api/analisis_statistik/pendapatan_total/${cabang.value.id}/${periode_awal}/${periode_akhir}/tanggal`)

        if (data != 'kosong') {
          cek_data_pendapatan.value = ''
          pendapatan_option.value.legend.data = data.legend
          pendapatan_option.value.xAxis.data = data.xAxis
          pendapatan_option.value.series = data.series
        } else {
          cek_data_pendapatan.value = 'Tidak Ada Data Kunjungan'
        }
    }

    onMounted(() => {
      getData()
      getKunjungan()
      getPendapatan()
    })

    return { result, format_nominal, kunjungan_option, pendapatan_option, cabang, cek_data_kunjungan, cek_data_pendapatan, hak_akses }
  }
}
</script>

<style src="@/assets/vue3-multiselect.css"></style>
<style scoped>
.chart {
  height: 400px;
  margin-top: 40px;
}
</style>