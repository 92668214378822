<template>
	<div>
		<!-- Main Sidebar Container -->
		  <aside class="main-sidebar sidebar-dark-primary elevation-4" style="overflow-x: hidden;overflow-y: scroll;">
		    <!-- Sidebar -->
		    <div class="sidebar" >
		      <!-- Sidebar user panel (optional) -->
		      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
		        <!-- <div class="image">
		          <img src="/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
		        </div> -->
		        <div class="info" style="width: 100%;">
				  <div class="w-full d-flex flex-column mb-2" style="height: auto;">
				 	<img :src="cabang.image != 'http://api.solumedis.com/images' ? cabang.image : defaultImage" alt="" style="width: 100px;height: 100px;display: block;margin-left: auto;margin-right: auto;">
				  </div>
		          <a href="#" class="d-block text-white text-center">{{ cabang.nama }}</a>
				  <p class="text-center" style="color: #CCCCCC;">{{ user.name }}</p>
		        </div>
		      </div>

		      <!-- SidebarSearch Form -->
		      <div class="form-inline">
		        <div class="input-group" data-widget="sidebar-search">
		          <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
		          <div class="input-group-append">
		            <button class="btn btn-sidebar">
		              <i class="fas fa-search fa-fw"></i>
		            </button>
		          </div>
		        </div>
		      </div>

		      <!-- Sidebar Menu -->
		      <nav class="mt-2">
		        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
		          <!-- Add icons to the links using the .nav-icon class
		               with font-awesome or any other icon font library -->
		          
		          <!-- Menu -->
		          <Dashboard v-if="cabang.kode_cabang != 'IDI'" />
		          <Administrasi :akses="hak_akses_store" v-if="credential.master && hak_akses.master && cabang.kode_cabang != 'IDI'" />
		          <RekamMedis :akses="hak_akses_store" v-if="credential.rekam_medis && hak_akses.rekam_medis && cabang.kode_cabang != 'IDI'" />
		          <Farmasi :akses="hak_akses_store" v-if="credential.farmasi && hak_akses.farmasi && cabang.kode_cabang != 'IDI'" />
		          <PelayananKlinik :akses="hak_akses_store" v-if="credential.pelayanan_klinik && hak_akses.pelayanan && cabang.kode_cabang != 'IDI'" />
		          <Akuntansi :akses="hak_akses_store" v-if="credential.akuntansi && hak_akses.akuntansi && cabang.kode_cabang != 'IDI'" />
		          <HRD :akses="hak_akses_store" v-if="credential.hrd && hak_akses.hrd && cabang.kode_cabang != 'IDI'" />
		          <Marketing :akses="hak_akses_store"  />
		          <Laporan :akses="hak_akses_store" v-if="credential.laporan && hak_akses.laporan && cabang.kode_cabang != 'IDI'" />
		          <AnalisisStatistik :akses="hak_akses_store" v-if="credential.analisis && hak_akses.analisis && cabang.kode_cabang != 'IDI'" />
		          <Membership v-if="cabang.kode_cabang == 'IDI'" />
		          <!-- End Menu -->
		        </ul>
		      </nav>
		      <!-- /.sidebar-menu -->
		    </div>
		    <!-- /.sidebar -->
  </aside>
	</div>
</template>

<script setup>
import Dashboard from './menu/Dashboard' 
import Administrasi from './menu/Administrasi'
import RekamMedis from './menu/RekamMedis'
import Farmasi from './menu/Farmasi'
import PelayananKlinik from './menu/PelayananKlinik'
import Akuntansi from './menu/Akuntansi'
import HRD from './menu/HRD'
import Marketing from './menu/Marketing'
import Laporan from './menu/Laporan'
import AnalisisStatistik from './menu/AnalisisStatistik'
import Membership from './menu/Membership'

import store from '@/store'
import { computed, onMounted, reactive, ref } from 'vue'
import axios from 'axios'

const user = computed(() => store.getters['auth/user'])
const cabang = computed(() => store.getters['auth/cabang'])
const hak_akses_store = computed(() => store.getters['auth/hak_akses'])
// CREDENTIAL PUSAT

const { credential, menuPusat, menuCabang } = useCredential()

const get_cabang = async () => {
	if (cabang.value.jenis == 'pusat') {
		menuPusat()
	} else {
		menuCabang()
	}
}
// END CREDENTIAL PUSAT

const defaultImage = ref(`https://ui-avatars.com/api/?name=${cabang.value.nama}&&background=0D8ABC&color=fff`)

const { hak_akses, get_hak_akses } = useCredentialAccess(user)

onMounted(() => {
	get_cabang()
	get_hak_akses()
})

function useCredentialAccess (user) {
	const hak_akses = reactive({
		'dashboard' : true,
		'master': false,
		'rekam_medis' : false,
		'farmasi' : false,
		'pelayanan_klinik': false,
		'akuntansi' : false,
		'hrd' : false,
		'pelayanan' : false,
		'laporan' : false,
		'analisis' : false,
	})

	const get_hak_akses = async () => {
		let { data } = await axios.get(`api/hak_akses/get_distinct/${user.value.id}`)

		if (data == 'kosong') {	
			full_access()
		} else {
			data.filter(generate_access)		
		}
	}

	const generate_access = (data) => {
		if (data.menu.nama == 'master') {
			hak_akses.master = true
		} else if (data.menu.nama == 'rekam_medis') {
			hak_akses.rekam_medis = true
		} else if (data.menu.nama == 'farmasi') {
			hak_akses.farmasi = true
		} else if (data.menu.nama == 'akuntansi') {
			hak_akses.akuntansi = true
		} else if (data.menu.nama == 'hrd') {
			hak_akses.hrd = true
		} else if (data.menu.nama == 'pelayanan') {
			hak_akses.pelayanan = true
		} else if (data.menu.nama == 'laporan_laporan') {
			hak_akses.laporan = true
		} else if (data.menu.nama == 'analisis_statistik') {
			hak_akses.analisis = true
		}
	}

	const full_access = () => {
		hak_akses.dashboard = true
		hak_akses.rekam_medis = true
		hak_akses.farmasi = true
		hak_akses.pelayanan_klinik = true
		hak_akses.akuntansi = true
		hak_akses.hrd = true
		hak_akses.pelayanan = true
		hak_akses.laporan = true
		hak_akses.master = true
		hak_akses.analisis = true
	}

	return { hak_akses, get_hak_akses }
}

function useCredential () {
	const credential = reactive({
		'dashboard': false,
		'master': false,
		'rekam_medis' : false,
		'farmasi' : false,
		'pelayanan_klinik': false,
		'akuntansi' : false,
		'hrd' : false,
		'pelayanan' : false,
		'laporan' : false,
		'analisis' : false,
	})

	const menuPusat = () => {
		credential.dashboard = true
		credential.master = true
		credential.rekam_medis = true
		credential.farmasi = true
		credential.pelayanan_klinik = false
		credential.akuntansi = true
		credential.hrd = true
		credential.pelayanan = true
		credential.laporan = true
		credential.analisis = true
	}

	const menuCabang = () => {
		credential.dashboard = true
		credential.master = true
		credential.rekam_medis = true
		credential.farmasi = true
		credential.pelayanan_klinik = true
		credential.akuntansi = true
		credential.hrd = true
		credential.pelayanan = true
		credential.laporan = true
		credential.analisis = true
	}

	return { credential, menuPusat, menuCabang }
}
	
</script>




<style>
	.menu-is-open{
		background-color: #494E53;
		transition: all 3s;
	}

	.show{
		display: block;
	}

	/*Scroll Bar*/
	/* width */
	::-webkit-scrollbar {
	  width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	  background: #494E53;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
	  background: #555;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	  background: #888;
	}

	.circular_image {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	overflow: hidden;
	background-color: blue;
	/* commented for demo
	float: left;
	margin-left: 125px;
	margin-top: 20px;
	*/
	
	/*for demo*/
	display:inline-block;
	vertical-align:middle;
	}
	.circular_image img{
	width:100%;
	}
</style>