<template>
  <div class="hold-transition login-page" style="background: rgb(41,128,185);
background: linear-gradient(148deg, rgba(41,128,185,1) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 75%, rgba(255,106,136,1) 100%);">
    <div class="login-box">    
      <div class="login-logo">
       </div>
       <!-- test -->
       
      <!-- /.login-logo -->
    <div class="item-logos">
      <img src="/assets/solumed.png" style="display:block; margin:auto" alt="logo" width="150" border="5" ><br>
    </div>
      <div class="card">      
        <div class="card-body login-card-body">        
      <p class="text-center "><b h3>Sistem Informasi Managemen</b><br> <b>Klinik, Apotek, Praktek Dokter &amp; Nakes Lainnya</b> </p>
          <p class="login-box-msg ">Silahkan Login</p>

          <form @submit.prevent="login">
            <div class="input-group mb-3">
              <input type="email" v-model="credential.email" class="form-control" placeholder="Email" />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                v-model="credential.password"
                type="password"
                class="form-control"
                placeholder="Password"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- /.col -->
              <div class="col mt-3">
                <button class="btn btn-primary btn-block text-center" id="button">
                  <div>
                    Sign In
                  </div>
                  <div>
                    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                      <g>
                        <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
                        <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                      </g>

                    </svg>
                  </div>
                </button>
              </div>
              <!-- /.col -->
            </div>
          </form>
          
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
    <!-- /.login-box -->
    
  </div>
  
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, reactive } from 'vue'
import Swal from 'sweetalert2'

export default {
  setup(){
    let loading = ref(false)

    const credential = reactive({
        email: '',
        password: ''
    })
    const login = async () => {
      loading.value = true
      await store.dispatch('auth/login', credential)
      .then((response) => {
        if (response == 'wrong_credential') {
          Swal.fire({
            text: 'Email atau Password Salah',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          })
          loading.value = false  
        } else {
          router.push('/dashboard')    
        }
      })
      
    }

    return {
      login,
      credential,
      loading
    }
  }
};
</script>
<style>


  #button{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg{
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
</style>