import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as dayjs from 'dayjs'


axios.interceptors.request.use((config) => {
	// config.baseURL = 'http://localhost:8000'
	config.baseURL = 'https://api.solumedis.com'
	config.withCredentials = true
	return config
})

store.dispatch('auth/me').then(() => {
	createApp(App).use(store).use(router).use(ElementPlus).use(dayjs).mount('#app')
})

