<template>
	<li class="nav-item" v-bind:class="{'menu-is-opening menu-open': pelayananActive}">
        <a href="#" class="nav-link" @click="pelayananActive = !pelayananActive">
          <i class="nav-icon fas fa-laptop-medical"></i>
          <p>
            Pelayanan Klinik
            <!-- <i class="right fas fa-angle-left"></i> -->
          </p>
        </a>
        <ul class="nav nav-treeview">
          <li class="nav-item pl-2" v-if="props.akses.includes('resepsionis') || props.akses.includes('semua')">
            <router-link to="/resepsionis" class="nav-link">
              <i class="fas fa-concierge-bell nav-icon"></i>
              <p class="text-sm">Resepsionis</p>
            </router-link>
          </li>
          <li class="nav-item pl-2">
            <router-link to="/appointment-pasien" class="nav-link">
              <i class="fas fa-calendar-check nav-icon"></i>
              <p class="text-sm">Appointment Pasien</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('perawatan_dokter') || props.akses.includes('semua')">
            <router-link to="/perawatan" class="nav-link">
              <i class="fas fa-user-md nav-icon"></i>
              <p class="text-sm">Perawatan/Dokter</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('farmasi') || props.akses.includes('semua')">
            <router-link to="/farmasi" class="nav-link">
              <i class="fas fa-tablets nav-icon"></i>
              <p class="text-sm">Farmasi</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('farmasi_penjualan_langsung') || props.akses.includes('semua')">
            <router-link to="/farmasi-penjualan-langsung" class="nav-link">
              <i class="fas fa-tablets nav-icon"></i>
              <p class="text-sm">Farmasi Penjualan Langsung</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('kasir') || props.akses.includes('semua')">
            <router-link to="/kasir" class="nav-link">
              <i class="fas fa-cash-register nav-icon"></i>
              <p class="text-sm">Kasir</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('data_transaksi_perawatan') || props.akses.includes('semua')">
            <router-link to="/transaksi-perawatan" class="nav-link">
              <i class="fas fa-file nav-icon"></i>
              <p class="text-sm">Data Transaksi Perawatan Pasien</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('data_transaksi_perawatan') || props.akses.includes('semua')">
            <router-link to="/surat" class="nav-link">
              <i class="fas fa-file nav-icon"></i>
              <p class="text-sm">Data Surat</p>
            </router-link>
          </li>
          <li class="nav-item pl-2">
            <router-link to="/" class="nav-link">
              <i class="fas fa-desktop nav-icon"></i>
              <p class="text-sm">Konsultasi Online</p>
            </router-link>
          </li>
        </ul>
      </li>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const pelayananActive = ref(false)

const props = defineProps({
  akses: Array
})
</script>

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>