import { createStore } from 'vuex'
import auth from './auth'
import sidebar from './sidebar'
import tindakan from './tindakan'
import dataDetailProduk from './data-detail-produk'
import poli from './poli'
import surat from './surat'
import pasien from './pasien'
import karyawan from './karyawan'
import jadwalShift from './jadwal-shift'
import shift from './shift'

export default createStore({
  modules: { auth, sidebar, tindakan, dataDetailProduk, poli, surat, pasien, karyawan, jadwalShift, shift }
})
