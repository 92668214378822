<template>
	<div class="spinner-border loading" role="status">
	  <span class="sr-only">Loading...</span>
	</div>
</template>

<style>
	.loading {
	  position: fixed;
	  z-index: 999;
	  height: 2em;
	  width: 2em;
	  overflow: show;
	  margin: auto;
	  top: 0;
	  left: 0;
	  bottom: 0;
	  right: 0;
	}

</style>