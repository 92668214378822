<template>
	<li class="nav-item menu-open">
		<!-- style="background-color: #ff975e;" -->
	    <router-link to="/" class="nav-link active">
	      <i class="nav-icon fas fa-tachometer-alt"></i>
	      <p>
	        Dashboard
	      </p>
	    </router-link>
	  </li>
</template>