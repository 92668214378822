import axios from 'axios'

export default ({
  namespaced: true,
  state: {
    karyawan : null,
    loading  : null
  },
  getters: {
    getKaryawan(state){
      return state.karyawan;
    }
  },
  mutations: {
    setKaryawan(state, value) { state.karyawan = value },
    setLoading(state, value) {
        state.loading = value
    }
  },
  actions: {
    async fetchDokter({commit}, id){
      commit("setLoading", true)
      await axios.get(`api/karyawan/getAllWhereJabatan/${id}/dokter`)
      .then((response) => {
        commit("setKaryawan", response.data)
      })
      .catch(() => {
        commit("setKaryawan", null)
      })
      .finally(() => {
        commit('setLoading', false)
      })
    },
    async fetchAll({commit}, id){
      commit("setLoading", true)
      await axios.get(`api/karyawan/getAll/${id}`)
      .then((response) => {
        commit("setKaryawan", response.data)
      })
      .catch(() => {
        commit("setKaryawan", null)
      })
      .finally(() => {
        commit('setLoading', false)
      })
    }
  }
})