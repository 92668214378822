<template>
  <div id="app">

  	<div v-if="authenticated" >
      <!-- <div v-if="toggle" style="position: absolute;width: 100%;height: 100%;background-color: black;opacity: 0.6;z-index: 2000;">Loading</div> -->
      <Navbar />
      <Sidebar />
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        
        <!-- Main Content -->
    			<router-view></router-view>
        <!-- End Main Content -->
      </div>
      <!-- <Footer /> -->
    </div>

    <Login v-else />  

  </div>
</template>


<script>
	import store from '@/store'
  import { computed } from 'vue'
  import Navbar from '@/components/Navbar'
  import Sidebar from '@/components/Sidebar'
  import Login from '@/views/auth/Login'
  // import Footer from '@/components/Footer'
  
  export default
  { 
    components: {
      Navbar: Navbar,
      Sidebar: Sidebar,
      Login: Login,
      // Footer: Footer,
    },
    setup(){
      const authenticated = computed(() => store.getters['auth/authenticated'])
      const user = computed(() => store.getters['auth/user'])
      

      return {
        authenticated, user
      }
    }
  }
</script>
<style>

</style>
