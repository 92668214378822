<template>
	<li class="nav-item" v-bind:class="{'menu-is-opening menu-open': hrdActive}">
        <a href="#" class="nav-link" @click="hrdActive = !hrdActive">
          <i class="nav-icon fas fa-users"></i>
          <p>
            Membership
          </p>
        </a>
        <ul class="nav nav-treeview">
          <li class="nav-item pl-2">
            <router-link to="/data-membership" class="nav-link">
              <i class="far fa-circle nav-icon"></i>
              <p class="text-sm">Data Membership</p>
            </router-link>
          </li>
          <!-- <li class="nav-item pl-2">
            <router-link to="/absensi-karyawan" class="nav-link">
              <i class="far fa-circle nav-icon"></i>
              <p class="text-sm">Data Absensi Karyawan</p>
            </router-link>
          </li> -->
        </ul>
      </li>
</template>

<script setup>
import { ref } from 'vue'

const hrdActive = ref(false)

</script>

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>