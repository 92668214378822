export default({
	state:{
		masterActive: false,
		rekamMedisActive: false,
		farmasiActive: false,
		pelayananKlinikActive: false,
		akuntansiActive: false,
		hrdActive: false,
		marketingActive: false,
		laporanActive: false,
	},
	getters:{
		masterActive(state){
			return state.masterActive
		},
		rekamMedisActive(state){
			return state.rekamMedisActive
		},
		farmasiActive(state){
			return state.farmasiActive
		},
		pelayananKlinikActive(state){
			return state.pelayananKlinikActive
		},
		akuntansiActive(state){
			return state.akuntansiActive
		},
		hrdActive(state){
			return state.hrdActive
		},
		marketingActive(state){
			return state.marketingActive
		},
		laporanActive(state){
			return state.laporanActive
		}
	},
	mutations:{
		SET_MAsTER(state, value) { state.masterActive = value},
		SET_REKAMMEDIS(state, value) { state.rekamMedisActive = value},
		SET_FARMASI(state, value) { state.farmasiActive = value},
		SET_PELAYANAN(state, value) { state.pelayananKlinikActive = value},
		SET_AKUNTANSI(state, value) { state.akuntansiActive = value},
		SET_HRD(state, value) { state.hrdActive = value},
		SET_MARKETING(state, value) { state.marketingActive = value},
		SET_LAPORAN(state, value) { state.laporanActive = value},
	},
	actions:{
		 masterChange({commit}, value){
			commit("SET_MASTER", value)
			commit("SET_REKAMMEDIS", false)
			commit("SET_FARMASI", false)
			commit("SET_PELAYANAN", false)
			commit("SET_AKUNTANSI", false)
			commit("SET_HRD", false)
			commit("SET_MARKETING", false)
			commit("SET_LAPORAN", false)
		},
	},
})