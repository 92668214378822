import axios from 'axios'

export default ({
  namespaced: true,
  state: {
    pasien    : null,
    loading : null
  },
  getters: {
    getPasien(state){
      return state.pasien;
    }
  },
  mutations: {
    setPasien(state, value) { 
      state.pasien = value 
    },
    setLoading(state, value) {
        state.loading = value
    }
  },
  actions: {
    async fetchPasien({commit}, payload){
      commit("setLoading", true)
      await axios.get(`api/pasien/getRemote/${payload.id}/${payload.keyword}/${payload.tipe_cabang}`)
      .then((response) => {
        commit("setPasien", response.data)
      })
      .finally(() => {
        console.log('loading')
        commit('setLoading', false)
      })
    }
  }
})
