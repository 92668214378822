<template>
	<li class="nav-item" v-bind:class="{'menu-is-opening menu-open': hrdActive}">
		<a href="#" class="nav-link" @click="hrdActive = !hrdActive">
			<i class="nav-icon fas fa-ad"></i>
			<p>
			Marketing
			<!-- <i class="right fas fa-angle-left"></i> -->
			</p>
		</a>
		<ul class="nav nav-treeview">
			<li class="nav-item">
			<a href="pages/charts/chartjs.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>Email Marketing</p>
			</a>
			</li>
			<li class="nav-item">
			<a href="pages/charts/flot.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>SMS</p>
			</a>
			</li>
			<li class="nav-item">
			<a href="pages/charts/inline.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>Whatsapp</p>
			</a>
			</li>
			<li class="nav-item">
			<a href="pages/charts/inline.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>Set up Mobile Phone</p>
			</a>
			</li>
			<li class="nav-item">
			<a href="pages/charts/inline.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>Bridging denga Website Compro</p>
			</a>
			</li>
			<li class="nav-item">
			<a href="pages/charts/inline.html" class="nav-link">
				<i class="far fa-circle nav-icon"></i>
				<p>Reseller</p>
			</a>
			</li>
		</ul>
	</li>
</template>

<script setup>
import { ref } from 'vue'

const hrdActive = ref(false)

</script>

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>